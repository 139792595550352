<template>
  <ModuleBuilder
    v-if="moduleStore.moduleId" 
    class="h-full"
  />
</template>

<script >
// lib
import { onMounted, onUnmounted } from '@vue/composition-api'; // components

import ModuleBuilder from '@/modules/builder/components/shared/module-builder/ModuleBuilder.vue'; // stores

import { useModuleStore } from '@/modules/builder/store/moduleStore'; // composables

import { useRoute } from '@/hooks/vueRouter';
import { nodeListDropdownTypes, useModuleGraph } from '@/modules/builder/components/module-graph/moduleGraph';
import { actionTypes, useActionSidebar } from '@/modules/builder/components/module-sidebar/action/actionSidebar';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const moduleStore = useModuleStore();
  const {
    allowedNodeListOptions
  } = useModuleGraph();
  const {
    allowedActionTypes
  } = useActionSidebar();
  onMounted(() => {
    allowedNodeListOptions.value = [nodeListDropdownTypes.BACKGROUND_ACTION, nodeListDropdownTypes.INTEGRATION, nodeListDropdownTypes.SCREEN];
    allowedActionTypes.value = Object.values(actionTypes).filter(actionType => actionType !== actionTypes.VARIABLE_SETTER);
    moduleStore.moduleId = route.params.moduleId;
    moduleStore.moduleVariablesToRetrieve = [moduleStore.moduleId];
  });
  onUnmounted(() => {
    moduleStore.moduleId = '';
  });
  return {
    moduleStore
  };
};

__sfc_main.components = Object.assign({
  ModuleBuilder
}, __sfc_main.components);
export default __sfc_main;
</script>
